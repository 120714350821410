import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import { Carousel } from 'react-responsive-carousel';
import HeaderText from '../../UI/Headers/HeaderText';

// Styles
const useStyles = makeStyles((theme) => ({
  serviceTitle: {
    color: theme.palette.interactive.main,
    fontWeight: 600,
  },
  serviceLongTitle: {
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10,
  },
  button: {
    marginTop: 20,
    fontWeight: 600,
  },
  description: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  player: {
    height: 300,
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 160,
    },
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  service: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.alternative.main,
      padding: theme.spacing(2),
      minWidth: '80vw',
      marginRight: theme.spacing(2),
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
  },
}));

const Services = (props) => {
  const classes = useStyles();
  const [currentFrame, setCurrentFrame] = useState(0);
  const { data } = props;
  const speed = 0.1;

  return (
    <Box pt={{ xs: 6, md: 14 }}>
      <Box className={classes.mainBox}>
        <HeaderText text1="Our" text2="Services" />
        <Typography variant="body1" className={classes.description}>{data.mainPage.servicesDescription}</Typography>
        <Box mt={8} />
        <Grid container>
          <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Carousel
                showArrows={false}
                showThumbs={false}
                infiniteLoop
                emulateTouch
                interval={3000}
                transitionTime={1000}
                autoPlay
                showStatus={false}
              >
                {data.services.filter((s) => s.title !== 'Cargo Handling').sort((a, b) => a.sortOrder - b.sortOrder).map((item, i) => (
                  <Box key={item.title} className={classes.service}>
                    <Grid container height="100%">
                      <Grid item container>
                        <Typography className={classes.serviceTitle} variant="body1">
                          {i + 1}
                          {' '}
                          |
                          {' '}
                          {item.title}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.serviceLongTitle}>{item.longTitle}</Typography>
                        <Typography variant="body1">{item.preamble}</Typography>
                      </Grid>
                      <Grid item container alignSelf="flex-end" alignItems="flex-end">
                        <Button
                          href={`/services/${item.linkAddress}`}
                          variant="contained"
                          className={classes.button}
                          size="large"
                          color="primary"
                        >
                          Learn more
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Carousel>
            </Box>
            {/* MOBILE */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Box className={classes.row}>
                {data.services.filter((s) => s.title !== 'Cargo Handling').sort((a, b) => a.sortOrder - b.sortOrder).map((item, i) => (
                  <Box key={item.title} className={classes.service}>
                    <Grid container height="100%">
                      <Grid item container>
                        <Typography className={classes.serviceTitle} variant="body1">
                          {i + 1}
                          {' '}
                          |
                          {' '}
                          {item.title}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.serviceLongTitle}>{item.longTitle}</Typography>
                        <Typography variant="body1">{item.preamble}</Typography>
                      </Grid>
                      <Grid item container alignSelf="flex-end" alignItems="flex-end">
                        <Button
                          href={`/services/${item.linkAddress}`}
                          variant="contained"
                          className={classes.button}
                          size="large"
                          color="primary"
                        >
                          Learn more
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7} order={{ xs: 1, md: 2 }}>
            <Player
              onEvent={(event) => {
                if (event === 'loop') {
                  setCurrentFrame(0);
                } else if (event === 'frame') {
                  setCurrentFrame(currentFrame + 1);
                }
              }}
              autoplay
              speed={speed}
              loop
              src="/static/animations/servicesWGH.json"
              className={classes.player}
            >
              {/* <Controls visible buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Services;
