/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@mui/material';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import HeaderText from '../../UI/Headers/HeaderText';

// Styles
const useStyles = makeStyles((theme) => ({
  image: {
    position: 'relative',
    height: 350,
    zIndex: 101,
    marginBottom: theme.spacing(30),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  subHeader: {
    color: theme.palette.interactive.main,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  header: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  backgroundBox: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.palette.alternative.main,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  imageTag: {
    width: '100%',
    height: '100%',
    layout: 'fill',
    objectFit: 'cover',
  },
}));

const Advantage = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <Box pt={{ xs: 4, md: 14 }} position="relative">
      <Box className={classes.mainBox}>
        <HeaderText text1="Our" text2="Advantage" />
        <Box mt={8} />
        <Grid container alignItems="flex-start" spacing={4}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Box position="relative" zIndex="100">
              <AnimatedOnScroll animationIn="fadeIn" animationInDuration={1000}>
                <Typography variant="h5" className={classes.subHeader}>{data.advantageSubTitle1}</Typography>
                <Typography variant="h3" className={classes.header}>{data.advantageTitle1}</Typography>
                <Typography variant="body1" className={classes.description}>{data.advantageDescription1}</Typography>
              </AnimatedOnScroll>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <Box position="relative">
              <AnimatedOnScroll className={classes.image} animationIn="fadeIn" animationInDuration={1000}>
                <Box className={classes.image}>
                  <img src={data ? data.advantagePicture1.url : '/'} className={classes.imageTag} alt="Advantage 1" />
                </Box>
              </AnimatedOnScroll>
              <AnimatedOnScroll animationIn="fadeIn" animationInDuration={500} screenOffset={-350}>
                <Box
                  className={classes.backgroundBox}
                  top={30}
                  left={-200}
                  width={700}
                  height={400}
                />
              </AnimatedOnScroll>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 3, md: 3 }}>
            <Box position="relative">
              <AnimatedOnScroll className={classes.image} animationIn="fadeIn" animationInDuration={1000}>
                <Box className={classes.image}>
                  <img src={data ? data.advantagePicture2.url : '/'} className={classes.imageTag} alt="Advantage 2" />
                </Box>
              </AnimatedOnScroll>
              <AnimatedOnScroll animationIn="fadeIn" animationInDuration={500} screenOffset={-350}>
                <Box className={classes.backgroundBox} top={-40} left={200} width={700} height={450} />
              </AnimatedOnScroll>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 4, md: 4 }}>
            <Box position="relative" zIndex="100">
              <AnimatedOnScroll animationIn="fadeIn" animationInDuration={1000}>
                <Typography variant="h5" className={classes.subHeader}>{data.advantageSubTitle2}</Typography>
                <Typography variant="h3" className={classes.header}>{data.advantageTitle2}</Typography>
                <Typography variant="body1">{data.advantageDescription2}</Typography>
              </AnimatedOnScroll>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 6, md: 5 }}>
            <Box position="relative" zIndex="100">
              <AnimatedOnScroll animationIn="fadeIn" animationInDuration={1000}>
                <Typography variant="h5" className={classes.subHeader}>{data.advantageSubTitle3}</Typography>
                <Typography variant="h3" className={classes.header}>{data.advantageTitle3}</Typography>
                <Typography variant="body1">{data.advantageDescription3}</Typography>
              </AnimatedOnScroll>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 5, md: 6 }}>
            <Box position="relative">
              <AnimatedOnScroll className={classes.image} animationIn="fadeIn" animationInDuration={1000}>
                <Box className={classes.image}>
                  <img src={data ? data.advantagePicture3.url : '/'} className={classes.imageTag} alt="Advantage 3" />
                </Box>
              </AnimatedOnScroll>
              <AnimatedOnScroll animationIn="fadeIn" animationInDuration={500} screenOffset={-350}>
                <Box className={classes.backgroundBox} top={70} left={-300} width={600} height={350} />
              </AnimatedOnScroll>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Advantage;
