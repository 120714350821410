/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { Typography } from '@material-ui/core';
import { Box } from '@mui/material';
// import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import { Carousel } from 'react-responsive-carousel';
import { Grid } from '@material-ui/core';
import HeaderText from '../../UI/Headers/HeaderText';

// Styles
const useStyles = makeStyles((theme) => ({
  quoteAuthor: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      marginTop: theme.spacing(8),
    },
  },
  quoteContainer: {
    width: 400,
    marginBottom: 100,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  customersText: {
    textAlign: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  subHeader: {
    color: theme.palette.interactive.main,
    textTransform: 'uppercase',
    fontSize: 56,
    fontWeight: 'bold',
  },
  header: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

const Customers = (props) => {
  const classes = useStyles();
  const { data } = props;
  const logoRowAmount = 3;

  const logoDataArray = data.reduce((rows, key, index) => (index % logoRowAmount === 0 ? rows.push([key])
    : rows[rows.length - 1].push(key)) && rows, []);

  return (
    <Box position="relative">
      <Box className={classes.mainBox}>
        <HeaderText text1="Selected" text2="Customers" />
        <Box>
          <Carousel
            showArrows={false}
            showThumbs={false}
            infiniteLoop
            emulateTouch
            interval={3000}
            transitionTime={1000}
            autoPlay
            showStatus={false}
            renderIndicator={false}
          >
            {
                logoDataArray.map((item, i) => (
                  <Grid container spacing={1} key={i}>
                    {
                      item.map((value, j) => (
                        <Grid item md={4} key={j}>
                          <img src={value ? value.logo.url : '/'} key={`image${j}`} objectFit="scale-down" width="100%" height="auto" alt="logos" />
                        </Grid>
                      ))
                    }
                  </Grid>
                ))
            }
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default Customers;
