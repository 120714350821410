/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable radix */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import CountUp, { useCountUp } from 'react-countup';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import VisibilitySensor from 'react-visibility-sensor';
import HeaderText from '../../UI/Headers/HeaderText';

// Styles
const useStyles = makeStyles((theme) => ({
  backgroundBox: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.alternative.main,
    zIndex: 103,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  captiveText: {
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 20,
  },
  statNumber: {
  },
  statText: {
    fontWeight: 100,
    textTransform: 'uppercase',
  },
  borderLeft: {
    borderLeft: 'solid',
    borderLeftColor: 'black',
    borderLeftWidth: 2,
    paddingLeft: 15,
    marginTop: 10,
  },
  image: {
    position: 'relative',
    height: 470,
    marginTop: 60,
    width: 'calc(100% + 100px)',
    left: -100,
    zIndex: 99,
    [theme.breakpoints.down('md')]: {
      height: 300,
      width: '100%',
      left: 0,
      marginTop: 0,
    },
  },
  imageTag: {
    width: '100%',
    height: '100%',
    layout: 'fill',
    objectFit: 'cover',
  },
}));

const Performance = (props) => {
  const classes = useStyles();
  const { data, statistics } = props;
  const [startCountDown, setStartCountDown] = useState(false);

  function onChange(isVisible) {
    if (isVisible && !startCountDown) {
      setStartCountDown(true);
    }
  }

  return (
    <Box pt={{ xs: 6, md: 14 }}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <AnimatedOnScroll animationIn="fadeIn" animationInDuration={1000} className={classes.backgroundBox}>
            <HeaderText text1="Our" text2="Performance" />
            <Typography variant="body1">{data.performanceDescription}</Typography>
            <Typography variant="body1" className={classes.captiveText}>{data.performanceCaptiveText}</Typography>
            <VisibilitySensor onChange={onChange}>
              <Box>
                {startCountDown
                  ? (
                    <Grid container>
                      <Grid item xs={12} sm={4} className={classes.borderLeft}>
                        <Typography variant="h6" className={classes.statNumber}>
                          <CountUp
                            end={parseInt(statistics.Pax.trim())}
                            duration={4}
                            useEasing
                            formattingFn={(value) => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`}
                          />
                        </Typography>
                        <Typography variant="caption" className={classes.statText}>
                          {data.performanceStatisticText1}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} className={classes.borderLeft}>
                        <Typography variant="h6" className={classes.statNumber}>
                          <CountUp
                            end={parseInt(statistics.Flights.trim())}
                            duration={4}
                            useEasing
                            formattingFn={(value) => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`}
                          />
                        </Typography>
                        <Typography variant="caption" className={classes.statText}>
                          {data.performanceStatisticText2}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} className={classes.borderLeft}>
                        <Typography variant="h6" className={classes.statNumber}>
                          <CountUp
                            end={parseInt(statistics.Luggage.trim())}
                            duration={4}
                            useEasing
                            formattingFn={(value) => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`}
                          />
                        </Typography>
                        <Typography variant="caption" className={classes.statText}>
                          {data.performanceStatisticText3}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : <Box>Waiting..</Box>}
              </Box>
            </VisibilitySensor>
          </AnimatedOnScroll>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AnimatedOnScroll animationIn="fadeIn" animationInDuration={1000}>
            <Box className={classes.image}>
              <img src={data ? data.performancePicture.url : '/'} className={classes.imageTag} alt="Performance" />
            </Box>
          </AnimatedOnScroll>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Performance;
