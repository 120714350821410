/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
import React from 'react';
import { HOME_PAGE } from '../lib/queries';
import client from '../lib/apolloClient';
import Layout from '../src/components/Layout/Layout';
import Hero from '../src/components/Home/Hero/Hero';
import Services from '../src/components/Home/Services/Services';
import Advantage from '../src/components/Home/Advantage/Advantage';
import Performance from '../src/components/Home/Performance/Performance';
import Customers from '../src/components/Home/Customers/Customers';
import replacePictureUrlWithSignedUrl from '../src/utils/replaceUrlWithSigned/replaceUrlWithSigned';

const Home = (props) => {
  const { data, statistics } = props;
  return (
    <Layout hero={<Hero data={data} />} fab whiteTextColor>
      <Performance data={data.mainPage} statistics={statistics} />
      <Services data={data} />
      <Advantage data={data.mainPage} />
      <Customers data={data.customerLogos} />
    </Layout>
  );
};

export default Home;

export async function getServerSideProps() {
  let statistics = { Pax: '10000', Luggage: '10000', Flights: '10000' };
  try {
    const statisticsResponse = await fetch(`${process.env.NEXT_PUBLIC_URL}/api/statistics`);
    if (statisticsResponse) {
      const incommingStatistics = await statisticsResponse.json();
      if (incommingStatistics && incommingStatistics.Pax) {
        statistics = incommingStatistics;
      }
    }
  } catch (e) {
    console.log('start');
    console.log(e);
    console.log('end');
  }
  let { data } = await client.query({ query: HOME_PAGE });
  data = await replacePictureUrlWithSignedUrl(data);

  return {
    props: {
      data,
      statistics,
    },
  };
}
