import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  text1: {
    color: 'black',
    fontWeight: 600,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  text2: {
    color: theme.palette.primary.main,
    marginBottom: '10px',
    fontWeight: 600,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
}));

const HeaderText = (props) => {
  const classes = useStyles();
  const { text1, text2 } = props;

  return (
    <Box>
      {text1 ? <Typography variant="h5" className={classes.text1}>{text1}</Typography> : null}
      {text2 ? <Typography variant="h2" className={classes.text2}>{text2}</Typography> : null}
    </Box>
  );
};

export default HeaderText;
