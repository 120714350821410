/* eslint-disable jsx-a11y/media-has-caption */
import { React } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button } from '@material-ui/core';
import Link from 'next/link';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';

// Styles
const useStyles = makeStyles((theme) => ({
  infoCard: {
    width: 450,
    position: 'absolute',
    textAlign: 'left',
    bottom: 60,
    padding: 20,
    left: '10vw',
    zIndex: 101,
    backgroundColor: '#fff',
    [theme.breakpoints.between('0', '600')]: {
      width: 250,
      left: '50%',
      bottom: 80,
      transform: 'translate(-50%, 0)',
    },
  },
  imageBox: {
    position: 'relative',
    display: 'block',
    height: '80vh',
    zIndex: 100,
    maxHeight: '900px',
    minHeight: '500px',
    '&:after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
    },
  },
  titleText: {
    fontSize: 23,
    color: 'black',
    marginBottom: 10,
    [theme.breakpoints.between('0', '600')]: {
      fontSize: 16,
    },
  },
  pressReleaseText: {
    fontWeight: 'Bold',
    color: 'black',
    fontSize: 16,
    [theme.breakpoints.between('0', '600')]: {
      fontSize: 12,
      fontWeight: 0,
    },
  },
  button: {
    marginTop: 20,
  },
  caption: {
    fontSize: 16,
    marginRight: 20,
    [theme.breakpoints.between('0', '600')]: {
      fontSize: 12,
    },
  },
  imageTag: {
    width: '100%',
    minHeight: '900px',
    objectFit: 'cover',
  },
}));

const Hero = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <Box>
      <Carousel
        showArrows
        showThumbs={false}
        infiniteLoop
        emulateTouch
        interval={7000}
        transitionTime={1000}
        autoPlay
        showStatus={false}
      >
        {data.newsArticles.map((item) => (
          <Box
            key={`image${item.title}`}
            className={classes.imageBox}
          >
            <Box>
              <img
                src={item ? item.picture.url : '/'}
                key={`image${item.title}`}
                className={classes.imageTag}
                alt="Main header"
              />
              <Box className={classes.infoCard}>
                <Typography variant="h1" className={classes.titleText} color="secondary">{item.title}</Typography>
                <Box classes={classes.pressRow} flexDirection="row" display="flex">
                  <Typography variant="caption" className={classes.caption}>{moment(item.publishedDate).format('YYYY-MM-DD - HH:mm')}</Typography>
                  <Typography className={classes.pressReleaseText} variant="caption" color="secondary">{item.tag}</Typography>
                </Box>
                <Link href={`/news/${item.id}`}>
                  <Button variant="contained" className={classes.button} size="large" color="primary">
                    {item.LinkText ? item.LinkText : 'Read more'}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default Hero;
